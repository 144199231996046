import ColoredSvg from '../CustomSvg/ColoredSvg';
import styles from './DataComparisonList.module.css';


const DataComparisonList = ({ suggestedUpdateList, title }) => {
  // console.log('suggestedUpdateList', suggestedUpdateList);

  return (
    <>
      {!!title && <div className={styles.topMessage}>{title}</div>}
      {suggestedUpdateList && suggestedUpdateList.length > 0 &&
        suggestedUpdateList.map((item) => (
          <div key={item.fieldName} className={styles.itemContainer}>
            <div className={styles.itemLabel}>{item.fieldLabel}</div>
            <div className={styles.itemContent}>
              <span className={styles.itemOldValue}>{item.old}</span>
              <ColoredSvg iconName={"arrow_right"} />
              <span className={styles.itemNewValue}>{item.new}</span>
            </div>
          </div>
        ))
      }
    </>
  );
};

export default DataComparisonList;
