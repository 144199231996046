/* eslint-disable no-undef */
import { useContext, useEffect, useId, useState } from 'react';
import axios from 'axios';
import { LoadingContext } from '../context/loadingContext';
import { PageContext } from '../context/context';
import { constants } from '../utils/constants';
import { parseNameString } from '../utils/helperFunctions';
import Alert from '@mui/material/Alert';
import AttachmentsPanel from '../components/Attachments/AttachmentsPanel';
import AttachmentsPanel2 from '../components/Attachments/AttachmentsPanel2';
import CreateRecord from '../components/RelatedLists/CreateRecord';
import CustomAvatar from '../components/User/CustomAvatar';
import DropDownActions from '../components/DropDowns/DropDownActions';
import IntroScreenLogButton from '../components/ActionButtons/IntroScreenLogButtons';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import NoEmailSelected from '../templates/NoEmailSelected';
import RelatedList from "../components/RelatedLists/RelatedList";
import WorkIcon from '@mui/icons-material/Work';

import styles from './templates.module.css';

/**
 * @summary Intro Screen Template
 * @interface IntroScreen
 * @description UI for app after login is complete to display information on the interaction to be logged.
 * This is the Default screen if user is logged in and is on an email or meeting
 */
const IntroScreen = () => {
  // console.log('from intro screen');
  const [createRecordConfig, setCreateRecordConfig] = useState(null);
  const [contactMap, setContactMap] = useState({});
  const [relatedItems, setRelatedItems] = useState({});
  const {
    attachmentsConfig,
    caseConfig,
    caseId,
    contactEmailFields,
    externalId,
    googleAuthUser,
    interactionId,
    isExchangeSystem,
    logError,
    logType,
    msAuthUser,
    onLogCase,
    onLogData,
    payload,
    pipeVersion,
    quickAddMenu,
    relatedListConfigs, setRelatedListConfigs,
    sections,
    salesforceUser,
    saveResultToast,
    setAutoPopStorage,
    setParentIdMap,
    setUserMenuAnchorEl,
    showUserMenu, setShowUserMenu,
    themeColors
  } = useContext(PageContext);
  const { setIsLoadingGlobal } = useContext(LoadingContext);

  const user = msAuthUser ? msAuthUser?.email?.substr(0, 1) : googleAuthUser?.email?.substr(0, 1);
  const isOutlook = constants.EMAIL_CLIENT === 'outlook' ? true : false;
  const userMenuCmpId = useId();

  /* START PROCESS PAYLOAD */
  useEffect(() => {
    // console.log('intro screen payload', payload)
    // console.log('intro screen item', Office.context.mailbox.item)
    setAutoPopStorage({});
    if (payload && salesforceUser) {
      const newContacts = {};
      if (logType === 'email') {
        const sourceFields = ['from', 'to', 'cc', 'bcc'];
        if (isOutlook) {
          sourceFields.forEach(item => {
            if (payload[item].length > 0 || (!!payload[item] && payload[item]?.constructor === Object) && Object.keys(payload[item]).length !== 0) {
              getEmailData(payload[item]).forEach(emailData => {
                newContacts[emailData?.email?.toLowerCase()] = { FirstName: emailData.FirstName, LastName: emailData?.LastName };
              });
            } else {
              // console.log('lost item...', payload[item])
            };
          });
        } else {
          payload.headers.forEach(item => {
            if (sourceFields.indexOf(item.name.toLowerCase()) >= 0) {
              getEmailData(item.value).forEach(emailData => {
                if (emailData.email.indexOf(",") > 0) {
                  emailData.email.split(',').forEach(item => {
                    const rawParts = item.trim().split("<");
                    let emailAddress = '';
                    let nameComponents = [];
                    let firstName = '';
                    let lastName = '';
                    const specialCharactersPattern = /[^a-zA-Z\s]/g;
                    if (rawParts.length === 1) {
                      emailAddress = rawParts[0].replace(">", "");
                      const localPart = emailAddress.split('@')[0];
                      nameComponents = localPart.replace(specialCharactersPattern, ' ').trim().split(/\s+/);
                    } else {
                      emailAddress = rawParts[1].replace(">", "");
                      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                      if (emailPattern.test(rawParts[0].replace(/['"]/g, "").trim())) {
                        const localPart = emailAddress.split('@')[0];
                        nameComponents = localPart.replace(specialCharactersPattern, ' ').trim().split(/\s+/);
                      } else {
                        nameComponents = rawParts[0].trim().split(/\s+/);
                      }
                    }
                    if (nameComponents[0]) {
                      firstName = nameComponents[0].charAt(0).toUpperCase() + nameComponents[0].slice(1).toLowerCase();
                    }
                    if (nameComponents[1]) {
                      lastName = nameComponents[1].charAt(0).toUpperCase() + nameComponents[1].slice(1).toLowerCase();
                    }
                    newContacts[emailAddress] = { FirstName: firstName, LastName: lastName };
                  });
                } else {
                  newContacts[emailData?.email?.toLowerCase()] = { FirstName: emailData.FirstName, LastName: emailData.LastName };
                }
              });
            };
          });
        };
      } else if (logType === 'meeting') {
        if (isOutlook) {
          // if (isExchangeSystem) {
          //   if (payload.ewsOrganizer?.emailAddress) {
          //     newContacts[payload.ewsOrganizer.emailAddress.toLowerCase()] = { FirstName: '', LastName: '' };
          //   };
          //   if (payload?.ewsRequiredAttendees && payload?.ewsRequiredAttendee?.length > 0) {
          //     payload?.ewsRequiredAttendee?.forEach(attendee => {
          //       newContacts[attendee.emailAddress.toLowerCase()] = { FirstName: '', LastName: '' };
          //     });
          //   };
          //   if (payload?.ewsOptionalAttendees && payload?.ewsOptionalAttendees?.length > 0) {
          //     payload?.ewsOptionalAttendees?.forEach(attendee => {
          //       newContacts[attendee.emailAddress.toLowerCase()] = { FirstName: '', LastName: '' };
          //     });
          //   };
          // } else {
          if (payload.organizer?.emailAddress) {
            newContacts[payload.organizer?.emailAddress?.toLowerCase()] = { FirstName: '', LastName: '' };
          };
          if (payload?.requiredAttendees && payload?.requiredAttendees?.length > 0) {
            payload?.requiredAttendees?.forEach(attendee => {
              newContacts[attendee?.emailAddress?.toLowerCase()] = { FirstName: '', LastName: '' };
            });
          };
          if (payload?.optionalAttendees && payload?.optionalAttendees?.length > 0) {
            payload?.optionalAttendees?.forEach(attendee => {
              newContacts[attendee?.emailAddress?.toLowerCase()] = { FirstName: '', LastName: '' };
            });
          };
          // };
        } else {
          if (payload.creator?.email) {
            newContacts[payload.creator.email.toLowerCase()] = { FirstName: '', LastName: '' };
          }
          if (payload.organizer?.email) {
            newContacts[payload.organizer.email.toLowerCase()] = { FirstName: '', LastName: '' };
          }
          payload.attendees?.forEach(attendee => {
            newContacts[attendee.email.toLowerCase()] = { FirstName: '', LastName: '' };
          });
        };
      };
      // console.log('intro screen new contacts', newContacts)
      setContactMap(newContacts);
      initializeRelatedData(newContacts);
    }
  }, [logType, payload, salesforceUser, sections]);


  useEffect(() => {
    setRelatedItems({});
  }, [externalId]);
  /* END PROCESS PAYLOAD */

  const showAttachments = () => {
    // console.log("Attachments Config", attachmentsConfig, logType, !!caseConfig);
    return (
      (attachmentsConfig?.forEmail && logType === 'email')
      || (attachmentsConfig?.forMeeting && logType === 'meeting')
      || (attachmentsConfig?.forCase && !!caseConfig)
    );
  };

  const getEmailData = (rawData) => {
    // console.log('Starting getEmailData with rawData:', rawData);
    const result = [];
    let firstName = '';
    let lastName = '';
    // Check if rawData is a string containing an email format
    if (typeof rawData === 'string' && rawData.includes('<')) {
      // console.log('Processing as single string email');
      const match = rawData.match(/"?(.*?)"?\s*<(.+)>/);
      if (match) {
        // console.log('Match found:', match);
        firstName = parseNameString(match[1].trim())?.firstName
        lastName = parseNameString(match[1].trim())?.lastName
        const email = match[2].trim();
        result.push({ email, FirstName: firstName, LastName: lastName });
      };
    } else if (Array.isArray(rawData)) {
      rawData.forEach(rawEmail => {
        // console.log('Processing as array', rawEmail?.displayName);
        firstName = parseNameString(rawEmail?.displayName)?.firstName
        lastName = parseNameString(rawEmail?.displayName)?.lastName
        result.push({ email: rawEmail.emailAddress, FirstName: firstName, LastName: lastName });
      });
    } else if (typeof rawData === 'object') {
      // console.log('Processing as object', rawData?.displayName);
      firstName = parseNameString(rawData?.displayName)?.firstName
      lastName = parseNameString(rawData?.displayName)?.lastName
      result.push({ email: rawData.emailAddress, FirstName: firstName, LastName: lastName });
    } else if (!isOutlook) {
      rawData.split(",").forEach(rawEmail => {
        const itemList = rawEmail.split("<");
        if (itemList.length > 1) {
          let firstName = '';
          let lastName = '';
          let nameParts = itemList[0].trim().split(" ");
          if (nameParts.length === 1) {
            const parsedEmail = nameParts[0].substring(0, nameParts[0].indexOf("@")).replace("\"", "");
            nameParts = parsedEmail.split(/\W+|\d+|_+|-+/g);
          };
          if (!!nameParts[0]) {
            firstName = nameParts[0];
          };
          if (!!nameParts[1]) {
            lastName = nameParts[1];
          };
          const email = itemList[1].trim();
          result.push({
            email: email.substring(0, email.indexOf(">")),
            FirstName: firstName,
            LastName: lastName,
          });
        } else {
          result.push({ email: itemList[0].trim(), FirstName: '', LastName: '' });
        };
      });
    };
    return result;
  };

  /**
   * @summary ...
   * @function
   * @memberof IntroScreen
   * @description ...
   * @param {string} record -...
   * @param {string} fields -...
   * @param {string} resultIsList -...
  */
  const getFieldValue = (record, fields, resultIsList) => {
    const valueList = [];
    fields?.forEach(fieldName => {
      const fnList = fieldName.split('.');
      let tempObj = record;
      fnList.forEach(fn => {
        if (tempObj) {
          tempObj = tempObj[fn];
        }
      });
      if (tempObj) valueList.push(tempObj);
    });
    return resultIsList ? valueList : valueList.join(". ");
  };

  /**
   * @summary ...
   * @function
   * @memberof IntroScreen
   * @description ...
   * @param {array} detail -...
  */
  const handleRelatedItemSelection = (detail) => {
    const items = [];
    detail.items.forEach(item => {
      if (item.record?.Id) {
        items.push(item.record.Id);
      }
    });
    setRelatedItems(prev => {
      const newList = { ...prev };
      newList[detail.inputId] = items;
      return newList;
    });
  };

  /**
   * @summary ...
   * @function
   * @memberof IntroScreen
   * @description ...
  */
  const handleCreateRecordSuccess = () => {
    setCreateRecordConfig(null);
    initializeRelatedData(contactMap);
  };

  /**
   * @summary ...
   * @function
   * @memberof IntroScreen
   * @description ...
   * @param {object} detail -...
  */
  const handleRelatedNewRecord = (detail) => {
    setCreateRecordConfig(detail.newRecordLayout);
  };

  /**
   * @summary ...
   * @function
   * @memberof IntroScreen
   * @description ...
   * @param {object} detail -...
  */
  const handleSelectAction = (detail) => {
    setCreateRecordConfig(detail.newRecordLayout);
  };

  const initializeRelatedData = async (newContacts) => {
    if (!relatedListConfigs || relatedListConfigs.length <= 0) return;
    setIsLoadingGlobal(true);

    let emailFields;
    if (contactEmailFields?.length > 0) {
      // Use email fields from the chrome extension layout config
      emailFields = [...contactEmailFields];
    } else {
      emailFields = ["Email", "CT_PE__Email_2__c", "CT_PE__Email_3__c", "CT_PE__Email_4__c"];
      // TODO: include the portal email field in the set above when pipe version is greater than 12
      if (pipeVersion > 12) {
        emailFields.push("CT_PE__Portal_Email__c");
      };
    };

    const emailContactMap = newContacts;
    const relatedConfigs = [...relatedListConfigs];
    relatedConfigs.forEach(config => {
      config.suggestedItems = null;
    });
    if (Object.keys(emailContactMap).length > 0) {
      const soqlString = generateContactEmailMatchSOQL(emailFields, Object.keys(emailContactMap));
      const url = `${constants.REACT_SERVER_URL}/api/v3/query/soql/${salesforceUser?.account}/${salesforceUser?.instance}`;
      const optionsV3 = { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-token': salesforceUser?.token, 'x-refresh': salesforceUser?.refresh, 'x-iv': salesforceUser?.iv } };
      let result;

      try {
        const response = await axios.post(url, { data: JSON.stringify(soqlString) }, optionsV3);
        result = await response.data;
      } catch (error) {
        logError({
          functionName: 'initializeRelatedData',
          error: error,
          additionalNotes: '',
          displayedToUser: false,
          level: 'error'
        });
        // console.log('error fetching related data', error);
        return setIsLoadingGlobal(false);
      };

      if (result?.records) {
        let internalIndex = -1, externalIndex = -1;
        relatedConfigs.forEach((config, i) => {
          if (config.isContactObject) {
            if (config.internal) {
              internalIndex = i;
            } else {
              externalIndex = i;
            }
            delete config.enableQuery;
          } else {
            config.enableQuery = true;
          }
        });

        const accountIdMap = {};
        // Add Salesforce Contact that matched emails to related list
        result.records.forEach(record => {
          let validEmail;
          emailFields.some(fn => {
            if (emailContactMap[record[fn]]) {
              validEmail = record[fn];
              return true; // no need to check for other values in the field list
            }
          });

          let collectParentId = false;
          if (validEmail) {
            const rtName = getFieldValue(record, ["RecordType.DeveloperName"]);
            let contactConfig;
            let matched = true;
            if (relatedConfigs?.[internalIndex]?.recordTypeAPINames?.indexOf(rtName) >= 0) {
              contactConfig = relatedConfigs[internalIndex];
            } else {
              contactConfig = relatedConfigs[externalIndex];
              matched = relatedConfigs[externalIndex].recordTypeAPINames.indexOf(rtName) >= 0;
            }
            if (contactConfig) {
              if (!contactConfig.suggestedItems) {
                contactConfig.suggestedItems = [];
              };
              if (!matched) {
                record.unmatched = "RecordType not matched";
              };
              contactConfig.suggestedItems.push(record);
            };
            collectParentId = !!contactConfig.parentIdField;
            emailContactMap[validEmail].Id = record.Id;
          };
          if (collectParentId && !!record.AccountId) {
            accountIdMap[record.Id] = record.AccountId;
          };
        });

        // Add remaining email without Salesforce Contact to related list
        Object.keys(emailContactMap).forEach(email => {
          if (!emailContactMap[email].Id) {
            const newRecord = emailContactMap[email];
            newRecord.Email = email;
            if (!!newRecord.FirstName || !!newRecord.LastName) {
              newRecord.Name = newRecord.FirstName + " " + newRecord.LastName;
            } else {
              // Guessing FirstName and LastName from email
              const nameParts = email.split("@")[0].split(/\W/g);
              // console.log('name parts', nameParts);
              newRecord.FirstName = nameParts[0].replace(nameParts[0].charAt(0), nameParts[0].charAt(0).toUpperCase());
              newRecord.Name = newRecord.FirstName;
              if (nameParts[1]) {
                newRecord.LastName = nameParts[1].replace(nameParts[1].charAt(0), nameParts[1].charAt(0).toUpperCase());
                newRecord.Name += " " + newRecord.LastName;
              };
            };
            // Add newRecord to relatedlist
            if (!relatedConfigs[externalIndex].suggestedItems) {
              relatedConfigs[externalIndex].suggestedItems = [];
            }
            relatedConfigs[externalIndex].suggestedItems.push(newRecord);
          }
        });
        setRelatedListConfigs(relatedConfigs);
        setParentIdMap(accountIdMap);
      };
    };

    setIsLoadingGlobal(false);
  };

  const generateContactEmailMatchSOQL = (emailFields, emailList) => {
    const fieldNameSet = new Set(['Id', 'FirstName', 'LastName', 'RecordTypeId', 'RecordType.DeveloperName', 'AccountId', 'Account.Name', 'Phone', 'Title', ...emailFields]);
    relatedListConfigs.forEach(config => {
      if (config.objectName === "Contact") {
        if (!!config.titleField) fieldNameSet.add(config.titleField);
        if (!!config.labelField) fieldNameSet.add(config.labelField);
        if (!!config.parentIdField) fieldNameSet.add(config.parentIdField);
        if (config.descriptionFields?.length > 0) {
          config.descriptionFields.forEach(df => {
            if (!!df.fieldName) fieldNameSet.add(df.fieldName);
          });
        };
      };
    });

    // escape any email addresses  that have an ' apostrophe in them
    const escapedEmailList = [];
    emailList.forEach(email => {
      escapedEmailList.push(email?.replace(/'/g, "\\'"));
    });

    const emailListString = escapedEmailList.join("','").toLowerCase();
    const emailFilters = [];
    emailFields.forEach(fn => {
      emailFilters.push(fn + " IN ('" + emailListString + "')");
    });
    const soqlString = "SELECT " + [...fieldNameSet.values()].join(",") + " FROM Contact WHERE " + emailFilters.join(" OR ");
    // console.log("Intro generateContactEmailMatchSOQL", soqlString);
    return soqlString;
  };

  return (
    <>
      {saveResultToast &&
        <div className="p-top_small">
          {saveResultToast}
        </div>
      }
      <>
        {!!createRecordConfig &&
          <div className={`${styles.introScreenCreateRecord} ${isOutlook && styles.inOffice}`}>
            <CreateRecord {...createRecordConfig}
              onCancel={e => setCreateRecordConfig(null)}
              onSuccessSave={handleCreateRecordSuccess}
            />
          </div>
        }
        <>
          {!externalId || (relatedListConfigs.length === 0) ?
            <div className="grid_center" style={{ flexDirection: "column", height: "85%" }}>
              <NoEmailSelected id={userMenuCmpId} user={user} externalId={externalId} />
            </div>
            :
            <>
              <section className={styles.introScreen}>
                {isOutlook && quickAddMenu &&
                  <div className={styles.outlookNav}>
                    {quickAddMenu?.[0]?.menuItems?.length > 0
                      ? <DropDownActions label="Create" actionList={quickAddMenu} onSelectAction={handleSelectAction} />
                      : <span></span>
                    }
                    <CustomAvatar
                      id={userMenuCmpId}
                      onClick={(event) => {
                        setUserMenuAnchorEl(event.currentTarget);
                        setShowUserMenu(!showUserMenu);
                      }}
                      salesforceUser={salesforceUser?.displayName}
                      themeColor={themeColors.tertiary}
                      user={user}
                    />
                  </div>
                }
                {caseConfig &&
                  <IntroScreenLogButton
                    buttonLabel={(!!caseId ? 'Update ' : 'Create ') + caseConfig.buttonLabel}
                    logType={caseConfig.buttonLabel}
                    onClickLog={() => onLogCase()}
                    recordId={caseId}
                    startIcon={<WorkIcon />} />
                }
                <IntroScreenLogButton
                  buttonLabel={(!!interactionId ? 'Update ' : 'Log ') + logType}
                  logType={logType}
                  onClickLog={() => onLogData({ relatedItems })}
                  recordId={interactionId}
                  startIcon={logType === 'email' ? <MailOutlineIcon /> : <MeetingRoomIcon />}
                />
                {!salesforceUser &&
                  <Alert variant="outlined" severity="warning" sx={{ marginTop: "1.5rem" }}>
                    You must log into Salesforce to see related data below
                  </Alert>
                }
                {quickAddMenu && !isOutlook && (quickAddMenu?.[0]?.menuItems?.length > 0) &&
                  <div>
                    <DropDownActions label="Create" actionList={quickAddMenu} onSelectAction={handleSelectAction} />
                  </div>
                }
              </section>

              {relatedListConfigs && relatedListConfigs.length > 0 &&
                relatedListConfigs.map((config, i) => (
                  (!config.requireParentId || !!interactionId) &&
                  <RelatedList {...config}
                    caseId={caseId}
                    externalId={externalId}
                    interactionId={interactionId}
                    key={`${i}-related-list`}
                    onCreateNewRecord={handleRelatedNewRecord}
                    onSelectItem={handleRelatedItemSelection}
                    salesforceUser={salesforceUser}
                    themeColors={themeColors}
                  />
                ))
              }

              {!!attachmentsConfig ?
                showAttachments() && (logType !== 'meeting' || isOutlook) &&
                <AttachmentsPanel2
                  enableCase={attachmentsConfig.forCase && !!caseConfig}
                  enableEmail={attachmentsConfig.forEmail && logType === 'email'}
                  enableMeeting={attachmentsConfig.forMeeting && logType === 'meeting'}
                  selectByDefaultCase={!caseId && !interactionId && attachmentsConfig.selectByDefaultCase}
                  selectByDefaultEmail={!caseId && !interactionId && attachmentsConfig.selectByDefaultEmail}
                  selectByDefaultMeeting={!caseId && !interactionId && attachmentsConfig.selectByDefaultMeeting} />
                :
                logType === 'email' && <AttachmentsPanel />
              }

              <div className="size_1-2 p-around_x-small" />
            </>
          }
        </>
      </>
    </>
  );
};

export default IntroScreen;
