import { useContext, useState } from 'react';
import { PageContext } from '../../context/context';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ColoredSvg from '../CustomSvg/ColoredSvg';
import DataComparisonList from '../DataComparisonList/DataComparisonList';
import ToastMessage from '../ToastMessage/ToastMessage';

import styles from './RelatedLists.module.css';


const ToolTip = (props) => {
  // console.log('tooltip props', props.disableQuickUpdate);
  const { aiLevel, disableQuickUpdate, displayError, enableCreateRecord, enableEdit, hasUpdates, isEditable, isNew, layout, objectName, onClickCreate, onClickEdit, onClickDirectSave, record, setDisplayError, title } = props || {}
  const isRecordEditable = enableCreateRecord && enableEdit && isEditable && objectName === 'Contact';
  const [showToolTip, setShowToolTip] = useState(false);
  const [showEditToolTip, setShowEditToolTip] = useState(false);
  const { logType, isMobile, themeColors } = useContext(PageContext);

  const creeateNewButton = () => {
    setShowToolTip(false);
    onClickCreate();
  };

  const editRecordButton = () => {
    setShowEditToolTip(false);
    onClickEdit();
  };

  const CreateNewToolTipContent = () => {
    const contentList = [];
    if (record) {
      layout.inputFields.forEach(inputConfig => {
        if (record[inputConfig.targetField]) {
          contentList.push({
            label: inputConfig.label,
            value: record[inputConfig.targetField]
          });
        } else if (record[inputConfig.attributes?.objectName]) {
          contentList.push({
            label: inputConfig.label,
            value: record[inputConfig.attributes.objectName]
          });
        }
      });
    };

    return (
      <div className={styles.ToolTipContentWrapper}>
        <IconButton aria-label={"close"} sx={{ color: "#ffffff", position: 'absolute', right: "-8px", top: "-12px" }} onClick={() => setShowToolTip(false)}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h3" sx={{ fontWeight: 400, fontSize: "12px", lineHeight: '16px' }}>
          New contact detected in this email. Create new contact to log with email:
        </Typography>

        <div className={styles.toolTipContent}>
          {contentList.map(item => (
            <div className={styles.toolTipContentKeyValues} key={item.label}>
              <Typography variant="body1" sx={{ fontSize: 12, fontWeight: 700 }}>
                {item.label}:
              </Typography>
              <Typography variant="body1" sx={{ fontSize: 12, fontWeight: 400 }}>
                {item.value}
              </Typography>
            </div>
          ))}
        </div>

        <hr />
        <div className={styles.ToolTipButtonWrapper}>
          <Button className={''} onClick={creeateNewButton} data-testid={`ea-ai-create-new`} sx={{ backgroundColor: themeColors.primary }} size="medium" variant="contained">Create New</Button>
        </div>
      </div>
    );
  };

  const ViewUpdateToolTipContent = ({ suggestions }) => {
    return (
      <div className={styles.ToolTipContentWrapper}>
        {
          displayError &&
          <ToastMessage
            close={()=>setDisplayError(null)}
            message={displayError}
            severity={'error'}
            title={'We ran into a problem'}
            type={'alert'}
          />
        }
        {isMobile &&
          <IconButton aria-label={"close"} sx={{ color: "#ffffff", position: 'absolute', right: "-8px", top: "-12px" }} onClick={() => setShowEditToolTip(false)}>
            <CloseIcon />
          </IconButton>
        }
        <DataComparisonList title={`Suggested updates detected in this ${logType} for ${title}`} suggestedUpdateList={suggestions} />
        <hr />
        <div className={styles.ToolTipButtonWrapper}>
          <Button className={styles.ToolTipActionButton} data-testid={`ea-ai-suggestions-edit`} onClick={editRecordButton} sx={{ color: themeColors.primary, backgroundColor: "#ffffff" }} size="medium" variant="contained">Edit Record</Button>
          <Button className={styles.ToolTipActionButton} disabled={disableQuickUpdate} data-testid={`ea-ai-suggestions-direct-save`} onClick={onClickDirectSave} sx={{ backgroundColor: themeColors.primary }} size="medium" variant="contained">Save Updates</Button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.aiContainer}>
      <Tooltip
        placement={"top-end"}
        slotProps={{ tooltip: { className: styles.aiToolTip } }}
        title={"Detected by InvestorFlow AI"}
      >
        <div className={styles.investorFlowIcon}>
          <ColoredSvg iconName={"ai"} />
        </div>
      </Tooltip>
      {isNew && aiLevel > 1 &&
        <Tooltip
          aria-label={"Open ToolTip Create New"}
          data-testid={`ea-ai-view-new`}
          onOpen={() => setShowToolTip(true)}
          onClose={() => setShowToolTip(false)}
          open={showToolTip}
          placement={"top-end"}
          slotProps={{ tooltip: { className: styles.aiToolTip } }}
          title={<CreateNewToolTipContent />}
        >
          <div className={styles.aiIcon}>
            <ColoredSvg iconName={"create_new"} />
          </div>
        </Tooltip>
      }
      {hasUpdates && hasUpdates.length > 0 && isRecordEditable && aiLevel > 1 &&
        <Tooltip
          aria-label={"Open ToolTip View Suggestions"}
          className={'aiWithContent'}
          data-testid={`ea-ai-view-suggestions`}
          onOpen={() => setShowEditToolTip(true)}
          onClose={() => setShowEditToolTip(false)}
          open={showEditToolTip}
          placement={"top-end"}
          slotProps={{ tooltip: { className: styles.aiToolTip } }}
          title={<ViewUpdateToolTipContent suggestions={hasUpdates} />}
        >
          <div className={styles.aiIcon}>
            <ColoredSvg iconName={"view_updates"} />
          </div>
        </Tooltip>
      }
    </div>
  );
};

export default ToolTip;
