import { useState } from "react";
import { Button } from "@mui/material";
import CreateRecord from "./CreateRecord";
import AddIcon from '@mui/icons-material/Add';

/**
 * EditModeSection provides a UI section to toggle between a button for initiating record creation and a form view for entering new record details.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.newRecordLayout - Configuration object for the new record form including field layouts and initial values.
 * @param {Function} props.onSuccessSave - Callback function that is executed after successfully saving the new record.
 * @returns {JSX.Element} A component that either displays a button to initiate creating a new record or the record creation form itself.
 *
 * @description
 * This component is part of a larger form or section where users can add new records. It conditionally renders:
 * - A button that when clicked, reveals a form to input new record details.
 * - A `CreateRecord` component that provides a form to enter details for a new record.
 * The transition between these two states is managed via local state using the `useState` hook.
 * The `onSuccessSave` callback is invoked after a successful save operation, allowing parent components to respond accordingly.
 */

const EditModeSection = (props) => {
  // console.log('EditModeSection props', props);
  const { newRecordLayout, onSuccessSave } = props || {};
  const [showCreateRecord, setShowCreateRecord] = useState(false);

  return (
    <div className="p-top_large">
       {showCreateRecord ?
        <CreateRecord isCompact
          {...newRecordLayout}
          onCancel={() => setShowCreateRecord(false)}
          onSuccessSave={(detail) => {
            onSuccessSave(detail);
            setShowCreateRecord(false);
          }} />
        :
        <div className="size_1-1 grid_reverse">
          <Button size="small" color="primary" variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => setShowCreateRecord(true)}>
            New
          </Button>
        </div>
      }
    </div>
  );
};

export default EditModeSection;
