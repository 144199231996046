import { useState } from 'react';
import { Tooltip } from '@mui/material';
import ColoredSvg from '../CustomSvg/ColoredSvg';
import styles from './Inputs.module.css';

const InputLabelCmp = (props) => {
  // console.log('from inputLabelCmp', props);
  const { value, required, isDetectedEntity } = props || {};
  return (
    <>
      <span>
        {value}
        {required && <span className={styles.requiredLabel}> *</span>}
      </span>
      {isDetectedEntity &&
        <span className={styles.aiContainer}>
          <Tooltip
            slotProps={{ tooltip: { className: styles.aiToolTip } }}
            title="Detected by InvestorFlow AI." >
            <span className={styles.investorFlowIcon}>
              <ColoredSvg iconName={"ai"} />
            </span>
          </Tooltip>
        </span>
      }
    </>
  )
}

export default InputLabelCmp
